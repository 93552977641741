"use strict";

const langCodeMap = {
	"en": "English",
	"de": "Deutsch",
	"es": "Español",
	"fr": "Français",
	"he": "עִבְרִית",
	"hu": "Magyar",
	"ja": "日本語",
	"pl": "Polski",
	"ru": "Русский",
	"zh-TW": "正體中文（臺灣）",
	"zh-HK": "繁體中文（香港）",
	"zh-CN": "简体中文（中国）"
};

let $a = function (selector = "*", source = document) {
	return source.querySelectorAll(selector);
};

$a(".nav-lang li>a").forEach((e) => {
	e.innerText = langCodeMap[e.innerText];
});

$a("#btn-lang")[0].innerHTML = $a(".nav-lang > li")[0].innerHTML || "Languages";

$a(".use-html").forEach((e) => {
	e.innerHTML = e.getAttribute("content");
});
$a(".info-expand").forEach((e) => {
	e.innerText = e.innerText.split(",")[parseInt(e.getAttribute("content"))];
});

// Shuffler
$a(".shuffler").forEach((e) => {
	let order = e.getAttribute("order").split(",");
	let children = [];
	while (e.children.length > 0) {
		let e0 = e.children[0];
		e0.remove();
		e0.category = e0.getAttribute("category");
		let weight = parseInt(e0.getAttribute("weight")) || 1;
		while (weight > 0) {
			children.push(e0);
			weight --;
		};
	};
	let newList = new Set();
	while (children.length > 0) {
		newList.add(children.splice(Math.floor(Math.random() * children.length), 1)[0]);
	};
	newList = Array.from(newList);
	newList.sort((a, b) => {
		let aI = order.indexOf(a.category),
		bI = order.indexOf(b.category);
		return Math.sign(aI - bI);
	});
	newList.forEach((e0) => {
		console.debug(e0);
		e.appendChild(e0);
	});
});
